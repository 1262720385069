import React from 'react'

import Page from '~/templates/Page'

const UeberUns = () => (
  <Page title="Über uns">
    <h1>Eine Stadt. Ein Verein. Eine Marke.</h1>
    <p>
      Das Feeling der Nürnberger Altstadt einzufangen und als alltagsfähige
      Kleidungsstücke zu präsentieren, war unser Ziel. Der Blick auf die
      Kaiserburg, das Wahrzeichen Nürnbergs und andere schöne altertümliche
      Ecken wie der Henkersteg und das Heilig-Geist-Spital, sollen zwar den
      Charm der Stadt widerspiegeln, aber auch als frische und moderne Mode
      angesehen werden. Uns geht es darum, dass diese Stadt noch mehr gelebt und
      geliebt wird, als wir es sowieso schon tun.{' '}
      <span role="img" aria-label="heart">
        ❤️
      </span>
    </p>
  </Page>
)

export default UeberUns
